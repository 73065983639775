import React, { useEffect, useState } from "react";
import {
  AddButton,
  Heading,
  HeadingRow,
  SearchContainer,
  WarningText,
} from "../SalonStylists/SalonStylists.styled";
import SearchBox from "../../Components/SearchBox/SearchBox";
import PlusIcon from "../../Assets/Svgs/PlusIcon";
import {
  InventoryNameContainer,
  InventoryNameDescription,
  InventoryNameInput,
  SeparateLine,
} from "./SalonInventory.styled";
import { Table, Tag, Modal, Button, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { getInventoryDetails, deleteInventoryDetails,addInventoryDetails} from "../../Api/ApiServerRequests/InventoryApiServerRequests";

const SalonInventory = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [inventoryName, setInventoryName] = useState("");
  const [inventoryNameValidationError, setInventoryNameValidationError] =
    useState(false);
  const [inventoryNameCountError, setInventoryNameCountError] = useState(false);
  const [TotalItemCount, setTotalItemCount] = useState(null);
  const [TotalItemCountValidationError, setTotalItemCountValidationError] =
    useState(false);
  const [TotalItemCountCountError, setTotalItemCountCountError] =
    useState(false);
  const ownerEmail = getUserEmails().salonOwnerEmail;
  const [inventoryData, setInventoryData] = useState([]);
  const [currentStockCount, setCurrentStockCount] = useState(null);
  const [
    currentStockCountValidationError,
    setCurrentStockCountValidationError,
  ] = useState(false);
  const [currentStockCountError, setCurrentStockCountError] = useState(false);
  const [lowStockPercentage, setLowStockPercentage] = useState(null);
  const [
    lowStockPercentageValidationError,
    setLowStockPercentageValidationError,
  ] = useState(false);
  const [lowStockPercentageCountError, setLowStockPercentageCountError] =
    useState(false);
  const [modalTitle, setModalTitle] = useState("Add new inventory");
  const [searchQuery, setSearchQuery] = useState("");

  const filteredInventoryData = inventoryData.filter((item) =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fetchInventoryData = async () => {
    try {
      const response = await getInventoryDetails(ownerEmail);
      if (response.status === 200) {
        const inventoryItems = Object.entries(response.data.data).map(
          ([itemName, itemDetails]) => ({
            item: itemName,
            lowStockAlert: `${itemDetails.low_stock_alert_percentage}%`,
            initialAvailableStock: itemDetails.total_stock,
            currentStockCount: itemDetails.current_stock,
            status: itemDetails.low_stock_status,
          })
        );
        //console.log(response.data.data);
        setInventoryData(inventoryItems);
      } else {
        console.error("Failed to fetch inventory data");
      }
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };
  useEffect(() => {

    fetchInventoryData();
  }, [ownerEmail]);

  const handleAddButtonClick = () => {
    setModalTitle("Add new inventory");
    setModalVisibility(true);
  };

  const closeModal = () => {
    setInventoryName("");
    setInventoryNameValidationError(false);
    setInventoryNameCountError(false);
    setTotalItemCount("");
    setTotalItemCountValidationError(false);
    setTotalItemCountCountError(false);

    setCurrentStockCount("");
    setCurrentStockCountValidationError(false);
    setCurrentStockCountError(false);

    setLowStockPercentage("");
    setLowStockPercentageValidationError(false);
    setLowStockPercentageCountError(false);

    setModalVisibility(false);
  };

  const columns = [
    { title: "Item", dataIndex: "item", key: "item" },
    {
      title: "Setup low stock alert",
      dataIndex: "lowStockAlert",
      key: "lowStockAlert",
    },
    {
      title: "Initial stock count",
      dataIndex: "initialAvailableStock",
      key: "initialAvailableStock",
    },
    {
      title: "Current stock count",
      dataIndex: "currentStockCount",
      key: "currentStockCount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "#117B34";
        if (status === "low stock") {
          color = "#98690C";
        } else if (status === "out of stock") {
          color = "#DE3B40";
        }
        return (
          <Tag color={color} style={{ borderRadius: "14px" }}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button
            type="link"
            onClick={() => handleEdit(record)}
            icon={<EditOutlined />}
          />
          <Button
            type="link"
            onClick={() => handleDelete(record)}
            icon={<DeleteOutlined />}
          />
        </span>
      ),
    },
  ];

  const handleEdit = (record) => {
    setModalTitle("Edit item");
    setInventoryName(record.item);
    setLowStockPercentage(parseInt(record.lowStockAlert));
    setCurrentStockCount(parseInt(record.currentStockCount));
    setTotalItemCount(parseInt(record.initialAvailableStock));
    setModalVisibility(true);
    //console.log("Editing item:", record);
  };

  const handleDelete = async (record) => {
    const itemName = record.item;
    try {
      const queryParams = new URLSearchParams({
        owner_email: ownerEmail,
        item_name: itemName,
      });

      const response = await deleteInventoryDetails(queryParams);
      if (response.data.status === 200) {
        message.success(response.data.message);
      }
      //console.log("response", response);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    //console.log("Deleting item:", record);
  };

  const handleAddInventory = async () => {
    // console.log("Item name:", InventoryName);
    // console.log("Initial stock count:", TotalItemCount);
    // console.log("Current stock count:", currentStockCount);
    // console.log("Low stock alert percentage:", lowStockPercentage);

    // if (currentStockCount > TotalItemCount) {
    //   message.error("Current stock count cannot be greater than initial stock count.");
    //   return;
    // }
    
    if (
      !inventoryName.trim() ||
      (TotalItemCount === "" || TotalItemCount === null) ||
      (currentStockCount === "" || currentStockCount === null) ||
      (lowStockPercentage === "" || lowStockPercentage === null)
    ) {
      message.error("Please enter data in every field.");
      return;
    }
    
    try {
      const queryParams = new URLSearchParams({
        owner_email: ownerEmail,
        item_name: inventoryName,
        total_stock_count: TotalItemCount,
        current_stock_count: currentStockCount,
        low_stock_alert_percentage: lowStockPercentage,
      });

      const response = await addInventoryDetails(queryParams);
      if (response.data.status === 201) {
        message.success("Item Added Successfully");
        fetchInventoryData()
      }else{
        message.error(response.data.data);
      }
      //console.log("response", response);
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <HeadingRow>
        <Heading>Inventory</Heading>
        <SearchContainer>
        <SearchBox onChange={(e) => setSearchQuery(e.target.value)} />
          <AddButton onClick={handleAddButtonClick}>
            <PlusIcon color="#fff" /> New Item
          </AddButton>
        </SearchContainer>
      </HeadingRow>
      <SeparateLine />

      <Table dataSource={filteredInventoryData} columns={columns} />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="Cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="Add" type="primary" onClick={handleAddInventory} style={{ backgroundColor: "rgba(109, 49, 237, 1)" }}>
            {modalTitle}
          </Button>,
        ]}
      >
        <InventoryNameContainer>
          <InventoryNameDescription>Item name</InventoryNameDescription>
          <InventoryNameInput
            value={inventoryName}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setInventoryName(input.slice(0, maxLength));
                setInventoryNameCountError(true);
                setInventoryNameValidationError(false);
              } else {
                setInventoryName(input);
                setInventoryNameValidationError(false);
              }
            }}
          />
          {inventoryNameValidationError && (
            <WarningText>Please enter the name.</WarningText>
          )}
          {inventoryNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </InventoryNameContainer>

        <InventoryNameContainer>
          <InventoryNameDescription>Initial stock count</InventoryNameDescription>
          <InventoryNameInput
            value={TotalItemCount}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (/^\d*$/.test(input) || input === "") {
                if (input.length > maxLength) {
                  setTotalItemCount(input.slice(0, maxLength));
                  setTotalItemCountCountError(true);
                  setTotalItemCountValidationError(false);
                } else {
                  setTotalItemCount(input);
                  setTotalItemCountValidationError(false);
                }
              } else {
                setTotalItemCountValidationError(true);
                setTotalItemCount("");
              }
            }}
            // disabled={modalTitle === "Edit item"}
          />
          {TotalItemCountValidationError && (
            <WarningText>Only numbers are allowed.</WarningText>
          )}
          {TotalItemCountCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </InventoryNameContainer>

        <InventoryNameContainer>
          <InventoryNameDescription>
            Current stock count
          </InventoryNameDescription>
          <InventoryNameInput
            value={currentStockCount}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (/^\d*$/.test(input) || input === "") {
                if (input.length > maxLength) {
                  setCurrentStockCount(input.slice(0, maxLength));
                  setCurrentStockCountError(true);
                  setCurrentStockCountValidationError(false);
                } else {
                  setCurrentStockCount(input);
                  setCurrentStockCountValidationError(false);
                }
              } else {
                setCurrentStockCountValidationError(true);
                setCurrentStockCount("");
              }
            }}
          />
          {currentStockCountValidationError && (
            <WarningText>Only numbers are allowed.</WarningText>
          )}
          {currentStockCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </InventoryNameContainer>

        <InventoryNameContainer>
          <InventoryNameDescription>
            Low stock alert percentage
          </InventoryNameDescription>
          <InventoryNameInput
            value={lowStockPercentage}
            onChange={(e) => {
              const input = e.target.value;
              const percentage = parseInt(input);

              // Check if the input is a valid number between 0 and 100
              if (input === "" || (percentage >= 0 && percentage <= 100)) {
                if (input.length > 3 || percentage > 100) {
                  setLowStockPercentage("100");
                  setLowStockPercentageCountError(true);
                  setLowStockPercentageValidationError(false);
                } else {
                  setLowStockPercentage(input);
                  setLowStockPercentageValidationError(false);
                  setLowStockPercentageCountError(false);
                }
              } else {
                setLowStockPercentageValidationError(true);
                setLowStockPercentage("");
              }
            }}
          />
          {lowStockPercentageValidationError && (
            <WarningText>Percentage must be between 0 and 100.</WarningText>
          )}
          {lowStockPercentageCountError && (
            <WarningText>Maximum value allowed is 100.</WarningText>
          )}
        </InventoryNameContainer>
      </Modal>
    </>
  );
};

export default SalonInventory;
