import styled, { css } from "styled-components";
import { Fonts } from "../../../Fonts/Fonts";
import { Theme } from "../../../Themes/Theme";

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.primaryFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  color: ${Theme.primaryBlack};
`;

export const MainContainer = styled.div`
  margin-top: 10px;
  padding: 0px 20px;
  min-height: calc(100vh - 10vh);
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border-width: 1px;
  border-color: none;
  border-style: none;
  padding: 10px;
  margin-top: 10px;
  gap: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(222, 225, 230, 1);
  border-style: solid;
`;

export const ProductInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductName = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : "rgba(23,26,31,1)")};
`;

export const ProductAmount = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${Theme.primaryBlack};
`;

export const SeparateLine = styled.div`
  width: 100%;
  height: 0px;
  border-width: 1px;
  border-color: ${Theme.primaryBlack};
  border-style: solid;
  transform: rotate(0deg);
  margin: 2px 0px;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  gap: 10px;
  bottom: 0;
  position: fixed;
`;

export const FinalAmount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const Success = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${Fonts.primaryFont};
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
  color: rgba(17, 123, 52, 1);
  background: rgba(238, 253, 243, 1);
  border-radius: 10px;
  padding: 5px;
  font-family: ${Fonts.primaryFont};
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(14, 100, 42, 1);
`;

export const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: start;
`;

export const CouponCode = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 60%;
`;

export const CouponCodeInput = styled.input`
  width: 100%;
  font-family: ${Fonts.primaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${Theme.primaryBlack};
  border: 1px solid ${Theme.primaryBlack};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${Theme.primaryBlack};
  }
`;

export const MandatoryContainer = styled.div`
  width: 90%;
  background: rgba(255,255,255,1);
  border-radius: 3px;
  border-width: 1px; 
  border-color: rgba(109,49,237,1);
  border-style: solid; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  gap:5px;
  font-family: ${Fonts.primaryFont};
  font-size: 12px; 
  line-height: 20px; 
  font-weight: 400; 
  color: rgba(109,49,237,1);
`;


export const YellowContainer = styled.div`
   background: rgba(255,211,23,0.11); 
  border-radius: 4px;
  width: 90%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  padding: 10px;
  margin-top: 10px;

`;

export const PaymentsBrandingContainer = styled.div`
  background: rgba(255,211,23,0.11); 
  border-radius: 4px;
  width: 90%;
  padding: 10px;
  display: flex;
  align-items: center; 
  justify-content: space-evenly; /* Ensures equal spacing */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  margin-top: 10px;
  
  img {
    flex: 1; /* Makes all images take equal space */
    max-width: 20%; /* Ensures 4 images fit in one row */
    min-width: 80px; /* Prevents images from getting too small */
    height: auto; /* Maintains aspect ratio */
    object-fit: contain; /* Ensures full visibility */
  }
`;


export const PointsText = styled.div`
   font-family: ${Fonts.primaryFont}; 
  font-size: 11px; 
  font-weight: 400; 
  line-height: 18px; 
  color: rgba(23,26,31,1);
  display: flex;
  align-items: center;
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Points = styled.div`
   font-family: ${Fonts.primaryFont}; 
  font-size: 20px; 
  font-weight: 400; 
  line-height: 30px; 
  color: rgba(23,26,31,1);
  display: flex;
  flex-direction: row;
`;

export const LineSeparator = styled.div`
   border-width: 1px; 
  border-color: rgba(255,240,175,1); 
  border-style: solid; 
  transform: rotate(270deg);
  width: 47px;
  height: 90%;
`;

export const LoyalpointContainer = styled.div`
 display: flex;
 flex-direction: column;
`;

export const UseLoyal = styled.div`
  font-family: ${Fonts.primaryFont};
  font-size: 11px; 
  line-height: 18px; 
  font-weight: 400; 
  color: rgba(23,26,31,1);
`;
