import React, { useEffect, useRef } from 'react';
import { createAPSSignature } from '../../Api/ApiServerRequests/BookingsApiServerRequests';
const host = process.env.SALON_APP_HOST;


const APSMerchantPage = ({amount,email}) => {
    const formRef = useRef(null);

    useEffect(() => {
        const createSignature = async () => {
            // let amount = 100;
            let currency = "SAR";
            const decimalMultipliers = {
                'AED': 100,   // 2 decimal places
                'JOD': 1000,  // 3 decimal places
                'SAR': 100    // 2 decimal places
            };
            
            let multiplier = decimalMultipliers[currency] || 100; // Default to 2 decimals
            let adjustedAmount = Math.round(amount * multiplier);
            const salonName=localStorage.getItem("salonName");
            try {
                let response = await createAPSSignature({
                    amount: adjustedAmount,
                    currency,
                    email,
                    salonName
                });
                console.log("response", response);
                const form = document.getElementById("form1");
                const keysOrder = [
                    'signature',
                    'command',
                    'merchant_reference',
                    'amount',
                    'access_code',
                    'merchant_identifier',
                    'currency',
                    'language',
                    'customer_email',
                    'return_url'
                ];
                keysOrder.forEach(key => {
                    if (response.data.hasOwnProperty(key)) {
                        console.log("key", key);
                        var input = document.createElement("input");
                        input.type = "hidden";
                        input.name = key;
                        input.value = response.data[key];
                        form.appendChild(input);
                    }
                });
                console.log("Form data before submission:", Array.from(new FormData(formRef.current).entries()));
                formRef.current.submit();
            } catch (error) {
                console.error("Error creating checkout:", error);
            }
        };
        createSignature();
    }, []);

    return (
        <div>
            <div className="overflow-hidden rounded-lg bg-white shadow py-10 ">
                <center>
                    <iframe name="myframe" src="" width="100%" height="500" allow="payment">
                    </iframe>
                </center>
                <div className="px-4 py-5 sm:p-6">
                    <form method="post"
                        action="https://sbcheckout.payfort.com/FortAPI/paymentPage"
                        id="form1"
                        name='form1'
                        // target='myframe'
                        target = "_self"
                        ref={formRef}>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default APSMerchantPage;