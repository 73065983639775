import React, { useEffect, useState } from "react";
import {
  DetailsContainer,
  Failure,
  FinalAmount,
  Header,
  MainContainer,
  SeparateLine,
  SubmitContainer,
  Success,
} from "../BookingDetails/BookingDetails.styled";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import {
  BookingConfirmationText,
  ConfirmationContainer,
  ProductAmount,
  ProductInformation,
  ProductName,
} from "./BookingConfirmation.styled";
import GreenTick from "../../../Assets/Svgs/GreenTick";
import TestingPDF from "../../../Assets/PDF/Receipt.pdf";
import Sidebar from "../../../Modules/Sidebar/Sidebar";
import { confirmBookingPayment, getBookingsConfirmationInformation } from "../../../Api/ApiServerRequests/BookingsApiServerRequests";
import { Theme } from "../../../Themes/Theme";
import { formatTimeRange, handleDownloadReceipt } from "../../../Helper/Helper";
import { message } from "antd";
import CrossIcon from "../../../Assets/Svgs/CrossIcon";
import CancelIcon from "../../../Assets/Svgs/CancelIcon";
import DisclaimerIcon from "../../../Assets/Svgs/DisclaimerIcon";
import RedTick from "../../../Assets/Svgs/RedCross";

const BookingConfirmation = () => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showHelp] = useState("Help");
  const [bookingInfo, setBookingInfo] = useState(null);
  const bookedId = sessionStorage.getItem("booked_id");
  const urlParams = new URLSearchParams(window.location.search);
  const cardNumber = urlParams.get('card_number');
  const status = urlParams.get('status');

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName!==salonName) {
      localStorage.setItem("salonName", potentialSalonName);
        window.location.href = `/${potentialSalonName}`;
    }
  }, []);

  useEffect(() => {
    const fetchBookingInformation = async () => {
      try {
        if(status === "success"){
          const response = await confirmBookingPayment(bookedId,status);
          if (response.data.status === 201) {
            message.success(response.data.data);
          }
        }
        else{
          const response = await confirmBookingPayment(bookedId,status);
          if (response.data.status === 201) {
            console.log("response",response)
            // message.success(response.data.data);
          }
        }
      // console.log("Confirmation response", response.data);
      //   navigate(`/${salonName}/booking-confirmation`);
      // } else {
      //   message.error("Something went wrong");
      // }
        const booking_id = bookedId;
        if (booking_id) {
          const response = await getBookingsConfirmationInformation(booking_id);
          // console.log("booking response", response.data.data);
          setBookingInfo(response.data.data);

        }
      } catch (error) {
        console.error("Error fetching booking information:", error);
      }
    };

    fetchBookingInformation();
  }, [bookedId]);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <MainContainer>
        <div>
          <Header>
            {/* <div
              onClick={() => {
                navigate(`/${salonName}/home`);
              }}
              style={{ display: "flex" }}
            >
              <LeftChevronIcon />
            </div> */}
            <div>{"Booking confirmation"}</div>
          </Header>
        </div>

        <ConfirmationContainer>
        {bookingInfo && (
          <>
          {status === "success" ?  <GreenTick /> : <RedTick />  }

         
          <BookingConfirmationText>
          {status === "success" ?  `Your Booking is Confirmed` : `Booking failed`  }
          </BookingConfirmationText>
          {/* {bookingInfo && (
            <LoyalPoints>
              Received <CrownIcon /> {bookingInfo.loyalty_points} Loyal points on
              this purchase
            </LoyalPoints>
          )} */}
          </>
        )}
        </ConfirmationContainer>

        <DetailsContainer>
          {bookingInfo && (
            <>
              <ProductInformation>
                <ProductAmount>{bookingInfo.date}</ProductAmount>
                <ProductName>Date</ProductName>
              </ProductInformation>
              <ProductInformation>
                <ProductAmount>
                  {formatTimeRange(
                    bookingInfo.selected_time[0],
                    bookingInfo.selected_time[1]
                  )}
                </ProductAmount>
                <ProductName>Time slot</ProductName>
              </ProductInformation>
              <ProductInformation>
                <ProductAmount>{bookingInfo.stylist_name}</ProductAmount>
                <ProductName>Stylist</ProductName>
              </ProductInformation>
              <SeparateLine />
              <ProductInformation>
                <ProductAmount>{bookingInfo.payment.source}</ProductAmount>
                <ProductName>{cardNumber}</ProductName>
              </ProductInformation>
              <ProductInformation>
                <ProductAmount>Paid</ProductAmount>
                <FinalAmount>
                {status === "success" ?   <Success>Success</Success> : <Failure>Failed</Failure>  }
                  SAR {bookingInfo.amount_paid}
                </FinalAmount>
              </ProductInformation>
            </>
          )}
        </DetailsContainer>

        <SubmitContainer>
          <Button
            text={"Help"}
            type={"default"}
            width={"100%"}
            height={"44px"}
            borderColor={"none"}
            onClick={toggleSidebar}
            color={Theme.salonColor}
          />

          <Button
            text={"Download Invoice"}
            type={"default"}
            width={"100%"}
            height={"44px"}
            color={Theme.salonColor}
            backgroundColor={"#F5F1FE"}
            onClick={handleDownloadReceipt}
          />

          <Button
            text={"Back to Home"}
            type={"primary"}
            width={"100%"}
            height={"44px"}
            onClick={() => {
              sessionStorage.removeItem("booked_id");
              navigate(`/${salonName}/home`);
            }}
            backgroundColor={Theme.salonColor}
          />
        </SubmitContainer>
      </MainContainer>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
        showHelp={showHelp}
      />
    </>
  );
};
export default BookingConfirmation;
