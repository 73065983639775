import React, { useState, useRef } from "react";
import {
  NameRow,
  Name,
  HeadingRow,
  SearchContainer,
  AddButton,
  ServiceProductContainer,
  InformationContainer,
  ImageContainer,
  InfoContainer,
  ServiceName,
  ServiceInfo,
  ServiceCost,
  ServiceTime,
  RequirementLabel,
  Requirements,
  ButtonContainer,
  EditButton,
  DeleteButton,
  UploadImageContainer,
  HiddenFileInput,
  ImageInput,
  LogoText,
  WarningText,
  ShopNameContainer,
  ShopNameDescription,
  ShopNameInput,
  DescriptionTextarea,
  ToggleContainer,
  SeparateLine,
  ItemsNeededContainer,
  DropdownContainer1,
  DropdownContainer2,
  AddMore,
  RemoveImage,
  RemoveItemButton,
} from "./SalonServices.styled";
import PlusIcon from "../../Assets/Svgs/PlusIcon";
import { Modal, Button, Switch, Select } from "antd";
import defaultImage from "../../Assets/Images/default_image.png";
import {
  getServices,
  insertService,
  updateRequirements,
  updateServices,
  deleteServices,
} from "../../Api/ApiServerRequests/ServicesApiServerRequests";

import {
  getProducts,
  insertProduct,
  updateProducts,
  deleteProducts,
} from "../../Api/ApiServerRequests/ProductsApiServerRequests";

import { message } from "antd";
import SearchBox from "../../Components/SearchBox/SearchBox";
import { useEffect } from "react";
import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal";
import NoData from "../../Components/NoData/NoData";
import CancelIcon from "../../Assets/Svgs/CancelIcon";
import { getUserEmails } from "../../Components/UserEmails/userEmails";
import { Theme } from "../../Themes/Theme";

const SalonServices = () => {
  const [activeTab, setActiveTab] = useState("services");
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isEditProductModalVisible, setIsEditProductModalVisible] =
    useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [shopName, setShopName] = useState("");
  const [shopNameValidationError, setShopNameValidationError] = useState(false);
  const [shopNameCountError, setShopNameCountError] = useState(false);
  const [shopDescriptionCountError, setShopDescriptionCountError] =
    useState(false);
  const [description, setDescription] = useState("");
  const [descriptionValidationError, setDescriptionValidationError] =
    useState(false);
  const [isService, setIsService] = useState(false);
  const { Option } = Select;
  const [price, setPrice] = useState("");
  const [priceValidationError, setPriceValidationError] = useState(false);
  const [requiredTime, setRequiredTime] = useState("");
  const [requiredTimeValidationError, setRequiredTimeValidationError] =
    useState(false);
  const [itemsNeeded, setItemsNeeded] = useState([]);
  const [currentItem, setCurrentItem] = useState("");
  const [currentQuantity, setCurrentQuantity] = useState("");
  const [quantityWarningVisible, setQuantityWarningVisible] = useState(false);
  const [products, setProducts] = useState();
  const [services, setServices] = useState();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [editProduct, setEditProduct] = useState(null);
  const [editedProductName, setEditedProductName] = useState("");
  const [editedProductDescription, setEditedProductDescription] = useState("");
  const [editedProductPrice, setEditedProductPrice] = useState("");
  const [editedProductImage, setEditedProductImage] = useState(null);
  const fileInputRef = useRef(null);
  const [editProductNameError, setEditProductNameError] = useState(false);

  const [editService, setEditService] = useState(null);
  const [editedServiceName, setEditedServiceName] = useState("");
  const [editedServiceDescription, setEditedServiceDescription] = useState("");
  const [editedServicePrice, setEditedServicePrice] = useState("");
  const [editedServiceImage, setEditedServiceImage] = useState(null);
  const [editedServiceTime, setEditedServiceTime] = useState("");
  const [isEditServiceModalVisible, setIsEditServiceModalVisible] =
    useState(false);
  const [editServiceNameError, setEditServiceNameError] = useState(false);
  const email = getUserEmails().salonOwnerEmail;

  const handleProductEditImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedProductImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleServiceEditImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedServiceImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditImageButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleEditProductButtonClick = (product) => {
    setEditProduct(product);
    setEditedProductName(product.name);
    setEditedProductDescription(product.description);
    setEditedProductPrice(product.price);
    setEditedProductImage(product.file_path);
    setIsEditProductModalVisible(true);
  };

  const handleEditServiceButtonClick = (service) => {
    setEditService(service);
    setEditedServiceName(service.name);
    setEditedServiceDescription(service.description);
    setEditedServicePrice(service.price);
    setEditedServiceImage(service.file_path);
    setEditedServiceTime(service.required_time);
    setIsEditServiceModalVisible(true);
  };

  const handleDeleteConfirmation = (itemName, isService) => {
    setProductToDelete({ name: itemName, isService: isService });
    setDeleteConfirmationVisible(true);
  };

  const handleDeleteClick = () => {
    setSelectedImage(null);
  };

  const removeItem = (indexToRemove) => {
    setItemsNeeded((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleConfirmDelete = async () => {
    if (productToDelete && productToDelete.name) {
      try {
        if (email) {
          const response = await (productToDelete.isService
            ? deleteServices
            : deleteProducts)(productToDelete.name, email);
          if (response && response.data.status === 200) {
            message.success(
              `${productToDelete.isService ? "Service" : "Product"} deleted successfully`
            );
            if (productToDelete.isService) {
              const updatedServices = filteredServices.filter(
                (service) => service.name !== productToDelete.name
              );
              setFilteredServices(updatedServices);
            } else {
              const updatedProducts = filteredProducts.filter(
                (product) => product.name !== productToDelete.name
              );
              setFilteredProducts(updatedProducts);
            }
            // Reset productToDelete and close modal
            setProductToDelete(null);
            setDeleteConfirmationVisible(false);
          } else {
            message.error("Failed to delete item");
          }
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        message.error("Failed to delete item");
      }
    }
  };
  const fetchProducts = async () => {
    try {
      if (email) {
        const response = await getProducts("email", email);
        //console.log("Products Response:", response);
        setProducts(response.data.data);
        setFilteredProducts(response.data.data);
        //console.log("products", products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchServices = async () => {
    try {
      if (email) {
        const response = await getServices("email", email);
        //console.log("Services Response:", response);
        setServices(response.data.data);
        setFilteredServices(response.data.data);
        //console.log("services", services);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {

    fetchProducts();
    fetchServices();
  }, []);

  const handleSearchInputChange = (event) => {
    if (activeTab === "services") {
      const query = event.target.value.toLowerCase();
      const filtered = services.filter((service) =>
        service.name.toLowerCase().includes(query)
      );
      setFilteredServices(filtered);
    } else if (activeTab === "products") {
      const query = event.target.value.toLowerCase();
      const filtered = products.filter((products) =>
        products.name.toLowerCase().includes(query)
      );
      setFilteredProducts(filtered);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddButtonClick = () => {
    setModalVisibility(true);
  };

  const closeModal = () => {
    setShopName("");
    setDescription("");
    setPrice("");
    setRequiredTime("");
    setSelectedImage(null);
    setItemsNeeded([]);
    setCurrentItem("");
    setCurrentQuantity("");
    setFileSizeError(false);
    setShopNameValidationError(false);
    setShopNameCountError(false);
    setShopDescriptionCountError(false);
    setDescriptionValidationError(false);
    setPriceValidationError(false);
    setRequiredTimeValidationError(false);
    setQuantityWarningVisible(false);
    setModalVisibility(false);
  };

  const closeEditProductModal = () => {
    setIsEditProductModalVisible(false);
  };

  const closeEditServiceModal = () => {
    setIsEditServiceModalVisible(false);
  };

  const handleAddMoreItems = () => {
    setItemsNeeded([...itemsNeeded, { item: "", quantity: "" }]);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setFileSizeError(true);
        return;
      } else {
        setFileSizeError(false);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOkButtonClick = async () => {
    let itemsList = {};
    if (isService) {
      // console.log("Service", {
      //   shopName,
      //   price,
      //   requiredTime,
      // });

      if (currentItem && currentQuantity) {
        itemsList.push({
          item: currentItem,
          quantity: parseInt(currentQuantity),
        });
      }

      //console.log("itemsList", itemsList);

      if (!shopName) {
        setShopNameValidationError(true);
        return;
      }

      if (!description) {
        setDescriptionValidationError(true);
        return;
      }

      if (!price) {
        setPriceValidationError(true);
        return;
      }

      if (!requiredTime) {
        requiredTimeValidationError(true);
      }

      if (
        !requiredTime.match(
          /^(\d+\s*hour(s?)|\d+\s*minute(s?)|\d+\s*hour(s?),\s*\d+\s*minute(s?))$/
        )
      ) {
        setRequiredTimeValidationError(true);
        return;
      }

      itemsNeeded.forEach((item) => {
        itemsList[item.item] = parseInt(item.quantity);
      });

      //console.log("Items List:", JSON.stringify(itemsList));

      try {
        const queryParams = new URLSearchParams({
          name: shopName,
          price: price,
          owner_email: email,
          required_time: requiredTime,
          description: description,
          required_items: JSON.stringify(itemsList),
        });

        //console.log("queryParams:", queryParams.toString());

        let imageBlob;
        if (selectedImage) {
          const response = await fetch(selectedImage);
          imageBlob = await response.blob();
        } else {
          const defaultImageResponse = await fetch(defaultImage);
          imageBlob = await defaultImageResponse.blob();
        }

        const formData = new FormData();
        formData.append("file", imageBlob);

        const uploadResponse = await insertService(queryParams, formData);
        //console.log("uploadresponse", uploadResponse);

        if (uploadResponse.data.status === 200) {
          console.log("itemsList", itemsList)
          fetchServices()

          const requiredItemsObject = {
            required_items: JSON.stringify(itemsList)
          };
          console.log("requiredItemsObject",requiredItemsObject)
          const newService = {
            name: shopName,
            price: price,
            required_time: requiredTime,
            description: description,
            file_path: selectedImage || defaultImage,
            required_items: requiredItemsObject,
          };
          console.log("new service", newService)
          setFilteredServices(prevServices => [...prevServices, newService]);
          message.success("Service inserted successfully");
        } else if (uploadResponse.data.status === 409) {
          message.success("Service already exists");
        } else {
          console.error("Failed to submit data to the server");
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        closeModal();
      }
    } else {
      // console.log("Product", {
      //   shopName,
      //   price,
      // });

      if (!shopName) {
        setShopNameValidationError(true);
        return;
      }

      if (!description) {
        setDescriptionValidationError(true);
        return;
      }

      if (!price) {
        setPriceValidationError(true);
        return;
      }

      try {
        const queryParams = new URLSearchParams({
          name: shopName,
          price: price,
          owner_email: email,
          description: description,
        });

        let imageBlob;
        if (selectedImage) {
          const response = await fetch(selectedImage);
          imageBlob = await response.blob();
        } else {
          const defaultImageResponse = await fetch(defaultImage);
          imageBlob = await defaultImageResponse.blob();
        }

        const formData = new FormData();
        formData.append("file", imageBlob);

        const uploadResponse = await insertProduct(queryParams, formData);
        //console.log("uploadresponse", uploadResponse);

        if (uploadResponse.data.status === 200) {
          const newProduct = {
            name: shopName,
            price: price,
            description: description,
            file_path: selectedImage || defaultImage,
          };
          fetchProducts()
          setFilteredProducts(prevServices => [...prevServices, newProduct]);
          message.success("Product inserted successfully");
        } else if (uploadResponse.data.status === 409) {
          message.success("Product already exists");
        } else {
          console.error("Failed to submit data to the server");
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        closeModal();
      }
    }
  };

  const handleProductEditOkButtonClick = async () => {
    if (!editedProductName.trim()) {
      setEditProductNameError(true);
      return;
    }
    try {
      const originalProductName = editProduct.name;
      const ownerEmail = email;

      // Construct form data
      const formData = new FormData();

      // if (editedProductImage) {
      //   const response = await fetch(editedProductImage);
      //   const imageBlob = await response.blob();
      //   formData.append("file", imageBlob);
      // }

      if(editedProductImage===editProduct.file_path){
      }else{
        const response = await fetch(editedProductImage);
        const imageBlob = await response.blob();
        formData.append("file", imageBlob);
      }

      // Send request to update product
      const queryParams = new URLSearchParams({
        name: originalProductName,
        owner_email: ownerEmail,
        modified_name: editedProductName,
        price: editedProductPrice,
        description: editedProductDescription,
      });

      let updateResponse;

      if(editedProductImage===editProduct.file_path){
        updateResponse = await updateProducts(queryParams)
      }else{
        updateResponse = await updateProducts(queryParams, formData);
      }

      //console.log("updateResponse", updateResponse);

      if (updateResponse.data.status === 201) {
        message.success("Product updated successfully");
        setFilteredProducts(prevProducts => {
          const updatedProducts = prevProducts.map(filteredProduct => {
            if (filteredProduct.name === originalProductName) {
              return {
                ...filteredProduct,
                name: editedProductName, // Corrected assignment
                price: editedProductPrice,
                description: editedProductDescription,
                file_path: editedProductImage
              };
            }
            return filteredProduct;
          });
          return updatedProducts;
        });
      } else {
        message.error("Failed to update product");
      }
    } catch (error) {
      message.error("Error updating product");
    } finally {
      closeEditProductModal();
    }
  };

  const handleServiceEditOkButtonClick = async () => {
    let itemsList = {};
    if (!editedServiceName.trim()) {
      setEditProductNameError(true);
      return;
    }
    itemsNeeded.forEach((item) => {
      itemsList[item.item] = parseInt(item.quantity);
    });
  
    try {
      const originalServiceName = editService.name;
      const ownerEmail = email;
  
      const formData = new FormData();
  
      if (editedServiceImage !== editService.file_path) {
        const response = await fetch(editedServiceImage);
        const imageBlob = await response.blob();
        formData.append("file", imageBlob);
      }
  
      const queryParams = new URLSearchParams({
        name: originalServiceName,
        owner_email: ownerEmail,
        price: editedServicePrice,
        modified_name: editedServiceName,
        required_time: editedServiceTime,
        description: editedServiceDescription,
      });
  
      let updateResponse;
      
      if (editedServiceImage === editService.file_path) {
        updateResponse = await updateServices(queryParams);
      } else {
        updateResponse = await updateServices(queryParams, formData);
      }
  
      if (updateResponse.data.status === 201) {
        // Update services state with the edited service
        setFilteredServices(prevServices => {
          const updatedServices = prevServices.map(filteredServices => {
            if (filteredServices.name === originalServiceName) {
              return {
                ...filteredServices,
                name: editedServiceName,
                price: editedServicePrice,
                required_time: editedServiceTime,
                description: editedServiceDescription,
                file_path: editedServiceImage
              };
            }
            return filteredServices;
          });
          return updatedServices;
        });
  
        // Display success messages
        message.success("Service updated successfully");
  
        // Update service requirements
        const requirementsParams = new URLSearchParams({
          owner_email: ownerEmail,
          name: originalServiceName,
          items: JSON.stringify(itemsList),
        });
        const requirementsResponse = await updateRequirements(requirementsParams);
        if (requirementsResponse.data.status === 201) {
          message.success("Requirements updated successfully");
        }
      } else {
        message.error("Failed to update service");
      }
    } catch (error) {
      console.error("Error updating service:", error);
      message.error("Error updating service");
    } finally {
      closeEditServiceModal();
    }
  };
  

  const handleProductcloseButtonClick = async () => {
    closeEditProductModal();
  };

  const handleServicecloseButtonClick = async () => {
    closeEditServiceModal();
  };

  return (
    <>
      <HeadingRow>
        <NameRow>
          <Name
            active={activeTab === "services"}
            onClick={() => handleTabClick("services")}
          >
            Services
          </Name>
          <Name
            active={activeTab === "products"}
            onClick={() => handleTabClick("products")}
          >
            Products
          </Name>
        </NameRow>
        <SearchContainer>
          <SearchBox onChange={handleSearchInputChange} />
          <AddButton onClick={handleAddButtonClick}>
            <PlusIcon color="#fff" /> Add
          </AddButton>
        </SearchContainer>
      </HeadingRow>

      {activeTab === "services" && (
        <>
          {services && services.length > 0 ? (
            <ServiceProductContainer>
              {/* {services &&
                services.map((service, index) => ( */}
              {filteredServices.map((service, index) => (
                <InformationContainer key={index}>
                  <ImageContainer>
                    <img src={service.file_path} alt={`Service ${index}`} />
                  </ImageContainer>
                  <InfoContainer>
                    <ServiceName>{service.name}</ServiceName>
                    <ServiceInfo>
                      <ServiceCost>SAR {service.price}</ServiceCost>
                      <ServiceTime>{service.required_time}</ServiceTime>
                    </ServiceInfo>
                    <RequirementLabel>Requirement:</RequirementLabel>
                    <Requirements>
                      {Object.entries(
                        JSON.parse(service.required_items.required_items)
                      ).map(([item, quantity]) => (
                        <span key={item}>
                          {item}: {quantity}{" "}
                        </span>
                      ))}
                    </Requirements>
                    <ButtonContainer>
                      <EditButton
                        onClick={() => handleEditServiceButtonClick(service)}
                      >
                        Edit
                      </EditButton>
                      <DeleteButton
                        onClick={() =>
                          handleDeleteConfirmation(service.name, true)
                        }
                      >
                        Delete
                      </DeleteButton>
                    </ButtonContainer>
                  </InfoContainer>
                </InformationContainer>
              ))}
            </ServiceProductContainer>
          ) : (
            <NoData />
          )}
        </>
      )}

      {activeTab === "products" && (
        <>
          {products && products.length > 0 ? (
            <ServiceProductContainer>
              {/* {products.map((product, index) => ( */}
              {filteredProducts.map((product, index) => (
                <InformationContainer key={index}>
                  <ImageContainer>
                    <img src={product.file_path} alt={`Product ${index}`} />
                  </ImageContainer>
                  <InfoContainer>
                    <ServiceName>{product.name}</ServiceName>
                    <ServiceInfo>
                      <ServiceCost>SAR {product.price}</ServiceCost>
                    </ServiceInfo>
                    <ButtonContainer>
                      <EditButton
                        onClick={() => handleEditProductButtonClick(product)}
                      >
                        Edit
                      </EditButton>
                      <DeleteButton
                        onClick={() =>
                          handleDeleteConfirmation(product.name, false)
                        }
                      >
                        Delete
                      </DeleteButton>
                    </ButtonContainer>
                  </InfoContainer>
                </InformationContainer>
              ))}
            </ServiceProductContainer>
          ) : (
            <NoData />
          )}
        </>
      )}

      <DeleteConfirmationModal
        visible={deleteConfirmationVisible}
        itemName={productToDelete && productToDelete.name}
        isService={productToDelete && productToDelete.isService}
        onCancel={() => setDeleteConfirmationVisible(false)}
        onConfirm={handleConfirmDelete}
      />

      <Modal
        title={`Add new`}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="Cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="Add" type="primary" style={{ backgroundColor: 'rgba(109, 49, 237, 1)' }} onClick={handleOkButtonClick}>
            OK
          </Button>,
        ]}
      >
        <UploadImageContainer>
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected Logo"
              style={{ width: "100%", height: "100%", borderRadius: "2px" }}
            />
          ) : (
            <>
              <HiddenFileInput
                id="fileInput"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              <ImageInput
                onClick={() => document.getElementById("fileInput").click()}
              >
                <PlusIcon />
                <LogoText>Image</LogoText>
              </ImageInput>
            </>
          )}
        </UploadImageContainer>
        {fileSizeError && <WarningText>Maximum file size is 10MB.</WarningText>}
        <ButtonContainer>
          <RemoveImage onClick={handleDeleteClick}>Remove Image</RemoveImage>
        </ButtonContainer>
        <ShopNameContainer>
          <ShopNameDescription>Name</ShopNameDescription>
          <ShopNameInput
            value={shopName}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 30;

              if (input.length > maxLength) {
                setShopName(input.slice(0, maxLength));
                setShopNameCountError(true);
                setShopNameValidationError(false);
              } else {
                setShopName(input);
                setShopNameValidationError(false);
              }
            }}
          />
          {shopNameValidationError && (
            <WarningText>Please enter the name.</WarningText>
          )}
          {shopNameCountError && (
            <WarningText>Maximum characters allowed is 30.</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Description</ShopNameDescription>
          <DescriptionTextarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionValidationError(false);

              const input = e.target.value;
              const maxLength = 100;

              if (input.length > maxLength) {
                setDescription(input.slice(0, maxLength));
                setShopDescriptionCountError(true);
                setDescriptionValidationError(false);
              } else {
                setDescription(input);
                setDescriptionValidationError(false);
              }
            }}
          />
          {descriptionValidationError && (
            <WarningText>Please enter the description.</WarningText>
          )}
          {shopDescriptionCountError && (
            <WarningText>Maximum characters allowed is 100.</WarningText>
          )}
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Price</ShopNameDescription>
          <ShopNameInput
            value={price}
            onChange={(e) => {
              const inputValue = e.target.value;

              if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                setPrice(inputValue);
                setPriceValidationError(false);
              } else {
                setPriceValidationError(true);
              }
            }}
          />
          {priceValidationError && (
            <WarningText>Please enter a valid numeric value.</WarningText>
          )}
        </ShopNameContainer>

        <ToggleContainer>
          <ShopNameDescription>
            Is it a service?
            <Switch
              checked={isService}
              onChange={(checked) => setIsService(checked)}
              style={{ 
                marginLeft: "10px",  
                backgroundColor: isService ? `${Theme.primaryColor}` : "gray" 
              }}
            />
          </ShopNameDescription>
        </ToggleContainer>

        {isService && (
          <>
            <ShopNameContainer>
              <ShopNameDescription>* Required Time</ShopNameDescription>
              <ShopNameInput
                value={requiredTime}
                onChange={(e) => setRequiredTime(e.target.value)}
                placeholder="Enter time format (e.g., 1 hour or 30 minutes or 1 hour, 30 minutes)"
              />
              {requiredTimeValidationError && (
                <WarningText>Please enter the valid required time.</WarningText>
              )}
            </ShopNameContainer>

            <SeparateLine></SeparateLine>
            <ShopNameContainer>
              <ShopNameDescription>Requirement</ShopNameDescription>
            </ShopNameContainer>

            {itemsNeeded.map((item, index) => (
              <ItemsNeededContainer key={index}>
                <DropdownContainer1>
                  <ShopNameDescription>Items needed</ShopNameDescription>
                  <ShopNameInput
                    placeholder="Enter Items Needed"
                    style={{ width: "100%" }}
                    value={item.item}
                    onChange={(e) =>
                      setItemsNeeded((prevItems) => {
                        const newItems = [...prevItems];
                        newItems[index].item = e.target.value;
                        return newItems;
                      })
                    }
                  />
                </DropdownContainer1>
                <DropdownContainer2>
                  <ShopNameDescription>Quantity</ShopNameDescription>
                  <ShopNameInput
                    placeholder="Enter Quantity"
                    style={{ width: "100%" }}
                    value={item.quantity}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        /^\d+$/.test(inputValue) &&
                        parseInt(inputValue) > 0
                      ) {
                        setItemsNeeded((prevItems) => {
                          const newItems = [...prevItems];
                          newItems[index].quantity = inputValue;
                          return newItems;
                        });
                        setQuantityWarningVisible(false);
                      } else {
                        setQuantityWarningVisible(true);
                      }
                    }}
                  />
                </DropdownContainer2>
                <RemoveItemButton onClick={() => removeItem(index)}>
                  <CancelIcon />
                </RemoveItemButton>
              </ItemsNeededContainer>
            ))}

            <AddMore onClick={handleAddMoreItems}>Add Item</AddMore>
          </>
        )}
      </Modal>

      {/* edit modal for product */}
      <Modal
        title={`Edit Product`}
        visible={isEditProductModalVisible}
        onCancel={handleProductcloseButtonClick}
        footer={[
          <Button key="Cancel" onClick={handleProductcloseButtonClick}>
            Cancel
          </Button>,
          <Button
            key="Add"
            type="primary"
            style={{ backgroundColor: 'rgba(109, 49, 237, 1)' }}
            onClick={handleProductEditOkButtonClick}
          >
            Update
          </Button>,
        ]}
      >
        <HiddenFileInput
          id="editFileInput"
          type="file"
          onChange={handleProductEditImageChange}
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
        />

        <UploadImageContainer>
          {editedProductImage ? (
            <img
              src={editedProductImage}
              alt="Selected Product"
              style={{ width: "100%", height: "100%", borderRadius: "2px" }}
            />
          ) : (
            <>
              <HiddenFileInput
                id="fileInput"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              <ImageInput
                onClick={() => document.getElementById("fileInput").click()}
              >
                <PlusIcon />
                <LogoText>Product Image</LogoText>
              </ImageInput>
            </>
          )}
        </UploadImageContainer>

        <Button onClick={handleEditImageButtonClick}>Edit Image</Button>

        <ShopNameContainer>
          <ShopNameDescription>Name</ShopNameDescription>
          <ShopNameInput
            value={editedProductName}
            onChange={(e) => {
              setEditedProductName(e.target.value);
              setEditProductNameError(false);
            }}
          />
          {editProductNameError && (
            <p style={{ color: "red" }}>Name should not be empty</p>
          )}
        </ShopNameContainer>
        <ShopNameContainer>
          <ShopNameDescription>Description</ShopNameDescription>
          <DescriptionTextarea
            value={editedProductDescription}
            onChange={(e) => setEditedProductDescription(e.target.value)}
          />
        </ShopNameContainer>
        <ShopNameContainer>
          <ShopNameDescription>Price</ShopNameDescription>
          <ShopNameInput
            value={editedProductPrice}
            onChange={(e) => setEditedProductPrice(e.target.value)}
          />
        </ShopNameContainer>
      </Modal>

      {/* edit modal for services */}
      <Modal
        title={`Edit Service`}
        visible={isEditServiceModalVisible}
        onCancel={handleServicecloseButtonClick}
        footer={[
          <Button key="Cancel" onClick={handleServicecloseButtonClick}>
            Cancel
          </Button>,
          <Button
            key="Add"
            type="primary"
            onClick={handleServiceEditOkButtonClick}
            style={{ backgroundColor: 'rgba(109, 49, 237, 1)' }}
          >
            Update
          </Button>,
        ]}
      >
        <HiddenFileInput
          id="editFileInput"
          type="file"
          onChange={handleServiceEditImageChange}
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
        />

        <UploadImageContainer>
          {editedServiceImage ? (
            <img
              src={editedServiceImage}
              alt="Selected Product"
              style={{ width: "100%", height: "100%", borderRadius: "2px" }}
            />
          ) : (
            <>
              <HiddenFileInput
                id="fileInput"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              <ImageInput
                onClick={() => document.getElementById("fileInput").click()}
              >
                <PlusIcon />
                <LogoText>Service Image</LogoText>
              </ImageInput>
            </>
          )}
        </UploadImageContainer>

        <Button onClick={handleEditImageButtonClick}>Edit Image</Button>

        <ShopNameContainer>
          <ShopNameDescription>Name</ShopNameDescription>
          <ShopNameInput
            value={editedServiceName}
            onChange={(e) => {
              setEditedServiceName(e.target.value);
              setEditServiceNameError(false);
            }}
          />
          {editServiceNameError && (
            <p style={{ color: "red" }}>Name should not be empty</p>
          )}
        </ShopNameContainer>
        <ShopNameContainer>
          <ShopNameDescription>Description</ShopNameDescription>
          <DescriptionTextarea
            value={editedServiceDescription}
            onChange={(e) => setEditedServiceDescription(e.target.value)}
          />
        </ShopNameContainer>
        <ShopNameContainer>
          <ShopNameDescription>Required Time</ShopNameDescription>
          <ShopNameInput
            value={editedServiceTime}
            onChange={(e) => setEditedServiceTime(e.target.value)}
            placeholder="Enter time format (e.g., 1 hour or 30 minutes or 1 hour, 30 minutes)"
          />
        </ShopNameContainer>
        <ShopNameContainer>
          <ShopNameDescription>Price</ShopNameDescription>
          <ShopNameInput
            value={editedServicePrice}
            onChange={(e) => setEditedServicePrice(e.target.value)}
          />
        </ShopNameContainer>

        <ShopNameContainer>
          <ShopNameDescription>Requirement</ShopNameDescription>
        </ShopNameContainer>

        {itemsNeeded.map((item, index) => (
          <ItemsNeededContainer key={index}>
            <DropdownContainer1>
              <ShopNameDescription>Items needed</ShopNameDescription>
              <ShopNameInput
                placeholder="Enter Items Needed"
                style={{ width: "100%" }}
                value={item.item}
                onChange={(e) =>
                  setItemsNeeded((prevItems) => {
                    const newItems = [...prevItems];
                    newItems[index].item = e.target.value;
                    return newItems;
                  })
                }
              />
            </DropdownContainer1>
            <DropdownContainer2>
              <ShopNameDescription>Quantity</ShopNameDescription>
              <ShopNameInput
                placeholder="Enter Quantity"
                style={{ width: "100%" }}
                value={item.quantity}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d+$/.test(inputValue) && parseInt(inputValue) > 0) {
                    setItemsNeeded((prevItems) => {
                      const newItems = [...prevItems];
                      newItems[index].quantity = inputValue;
                      return newItems;
                    });
                    setQuantityWarningVisible(false);
                  } else {
                    setQuantityWarningVisible(true);
                  }
                }}
              />
            </DropdownContainer2>
            <RemoveItemButton onClick={() => removeItem(index)}>
              <CancelIcon />
            </RemoveItemButton>
          </ItemsNeededContainer>
        ))}

        <AddMore onClick={handleAddMoreItems}>Add Item</AddMore>
      </Modal>
    </>
  );
};

export default SalonServices;
