import ApiServer from "../ApiServer";


export const createBookings = (queryParams,data) => {
  return ApiServer({
    method: "POST",
    url: `/bookings?${queryParams.toString()}`,
    data: data,
  });
};

export const getBookingsInformation = (value, type) => {
  let url;
  if (type === "booking id") {
    url = `/bookings?booking_id=${value}`;
  } else if (type === "owner email") {
    url = `/bookings?salon_owner_email=${value}`;
  }else if (type === "stylist email") {
    url = `/bookings?stylist_email=${value}`;
  }

  return ApiServer({
    method: "GET",
    url: url,
  });
};

export const getFilteredBookingsInformation = (queryParams) => {
  return ApiServer({
    method: "GET",
    url: `/bookings?${queryParams.toString()}`
  });
};

export const filteredAmountOfBookings = (queryParams) => {
  return ApiServer({
    method: "GET",
    url: `/bookings/total-amount?${queryParams.toString()}`
  });
};

export const confirmBookingPayment = (booking_id,status) => {
  return ApiServer({
    method: "POST",
    url: `/bookings/payment?booking_id=${booking_id}&status=${status}`,
  });
};


export const createAPSSignature = (data) => {
  return ApiServer({
    method: "POST",
    url: `/payments/create-signature`,
    data
  });
};
// export const fetchPaymentStatus = (checkoutId) => {  // Accept checkoutId as an argument
//   return ApiServer({
//     method: "GET",
//     url: `/payments/payment-details`,
//     params: { checkoutId },  // Pass checkoutId as a query parameter
//   });
// };
export const registerCardForSubscription = () => {
  return ApiServer({
    method: "POST",
    url: `/salon/start-subscription`,
    // params: { checkoutId }, 
  });

}
export const getBookingsConfirmationInformation = (booking_id) => {
  return ApiServer({
    method: "GET",
    url: `/bookings/payment?booking_id=${booking_id}`,
  });
};

export const getUpcomingActivites = (queryParams) => {
  return ApiServer({
    method: "GET",
    url: `/bookings?${queryParams.toString()}`
  });
};

export const cancelBooking = (booking_id) => {
  return ApiServer({
    method: "PUT",
    url: `/bookings/cancel?booking_id=${booking_id}`
  });
};

export const assignStylist = (booking_id, stylist_email) => {
  return ApiServer({
    method: "POST",
    url: `/bookings/assign-stylist?booking_id=${booking_id}&stylist_email=${stylist_email}`
  });
};

export const rescheduleBookings = (queryParams) => {
  return ApiServer({
    method: "PUT",
    url: `/bookings/reschedule?${queryParams.toString()}`,
  });
};

export const updateBookingInformation = (booking_id, processed) => {
  return ApiServer({
    method: "POST",
    url: `/bookings/completed?booking_id=${booking_id}&processed=${processed}`,
  });
};


export const deleteBookings = (booking_id) => {
  return ApiServer({
    method: "PUT",
    url: `/bookings?booking_id=${booking_id}`
  });
};
