import React from "react";

const RedTick = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="75" viewBox="0 0 74 75" fill="none">
      <rect y="0.758667" width="74" height="74" rx="37" fill="#D32F2F" />
      <path d="M23 23L51 51" stroke="white" strokeWidth="2.5" strokeLinecap="square" />
      <path d="M51 23L23 51" stroke="white" strokeWidth="2.5" strokeLinecap="square" />
    </svg>
  );
};

export default RedTick;
