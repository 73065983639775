import React, { useEffect, useState } from "react";
import {
  Header,
  MainContainer,
  AddressFormContainer,
  FormInputContainer,
  FormTitle,
  FormInput,
  FormInputTextArea,
  SubmitContainer,
  AddAddressButton,
  AddressCard,
  CardContainer,
  ImageContainer,
  ActionButtonContainer,
  UploadImageContainer,
  HiddenFileInput,
  ImageInput,
  AddressLocation,
  LocationContainer,
} from "./AddressPage.styled";
import { useNavigate } from "react-router-dom";
import LeftChevronIcon from "../../../Assets/Svgs/LeftChevronIcon";
import Button from "../../../Components/Button/Button";
import { Card, Radio, Modal, message } from "antd";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import PlusIcon from "../../../Assets/Svgs/PlusIcon";
import {
  ButtonContainer,
  LogoText,
  WarningText,
} from "../../../Modules/SalonStylists/SalonStylists.styled";
import { RemoveImage } from "../../../Modules/SalonServices/SalonServices.styled";
import {
  addCustomerAddress,
  deleteCustomerAddress,
  editCustomerAddress,
  getCustomerAddress,
} from "../../../Api/ApiServerRequests/CustomersApiServerRequests";
import { Button as AntButton } from "antd";
import LocationIcon from "../../../Assets/Svgs/LocationIcon";
import MapPopup from "../../../Components/MapPopup/MapPopup";
import { Theme } from "../../../Themes/Theme";

const RadioGroup = Radio.Group;

const AddressPage = ({ address }) => {
  const navigate = useNavigate();
  const salonName = localStorage.getItem("salonName");
  const customerNumber = localStorage.getItem("customerPhoneNumber");

  const [hasAddress, setHasAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [selectedAddressID, setSelectedAddressID] = useState(null);

  // For adding a new address
  const [name, setName] = useState("");
  const [addressText, setAddressText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  // For editing an address
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);

  // Validation error states
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [imageError, setImageError] = useState("");
  const [emailError, setEmailError] = useState("");

  // For map & location
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    // In case the user directly navigates or the salonName changes in the URL
    const pathArray = window.location.pathname.split("/");
    const potentialSalonName = pathArray[1];
    if (potentialSalonName !== salonName) {
      localStorage.setItem("salonName", potentialSalonName);
      window.location.href = `/${potentialSalonName}`;
    }
  }, [salonName]);

  async function fetchCustomerAddress() {
    const queryParams = new URLSearchParams({
      phone: customerNumber,
      salon_url_name: salonName,
    });
    try {
      const response = await getCustomerAddress(queryParams);
      setCustomerAddresses(response.data.data[0].address);
    } catch (error) {
      console.error("Error fetching customer address:", error);
    }
  }
  useEffect(() => {

    fetchCustomerAddress();
  }, [customerNumber, salonName]);

  const handleDelete = (addressId) => {
    setIsModalVisible(true);
    setSelectedAddressID(addressId);
  };

  const handleModalOk = async () => {
    const queryParams = new URLSearchParams({
      customer_phone: customerNumber,
      address_id: selectedAddressID,
    });
    try {
      const response = await deleteCustomerAddress(queryParams);
      if (response.data.status === 422) {
        message.error(response.data.data);
      } else if (response.data.status === 201) {
        message.success(response.data.data);
      }

      // Refetch addresses after deletion
      const refetchParams = new URLSearchParams({
        phone: customerNumber,
        salon_url_name: salonName,
      });
      const refetchResponse = await getCustomerAddress(refetchParams);
      setCustomerAddresses(refetchResponse.data.data[0].address);
    } catch (error) {
      message.error("Error deleting address. Please try again later.");
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRadioChange = (e) => {
    setSelectedAddress(e.target.value);
    setHasAddress(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setFileSizeError(true);
        return;
      } else {
        setFileSizeError(false);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    if (editingAddress) {
      setEditingAddress({ ...editingAddress, file_path: null });
    }
  };

  /**
   * Validate email format with a simple regex
   */
  const validateEmail = (emailString) => {
    // Basic email pattern: you can use something more sophisticated if needed
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(emailString);
  };

  const handleSubmit = async () => {
    if (hasAddress) {
      /**
       * User has selected an existing address from the radio buttons
       */
      const selectedAddressData = customerAddresses.find(
        (addr) => addr.id === selectedAddress
      );
      if (!selectedAddressData) {
        message.error("Please select a valid address.");
        return;
      }

      // Store in sessionStorage for subsequent steps:
      const addressObject = {
        name: selectedAddressData.name,
        address: selectedAddressData.address,
        phone: selectedAddressData.phone,
        file_path: selectedAddressData.file_path,
        email: selectedAddressData.email, // store email
      };
      sessionStorage.setItem(
        "selectedAddress",
        JSON.stringify({ address: addressObject })
      );

      // Proceed to next page
      navigate(`/${salonName}/offers`);
    } else {
      /**
       * User is adding a new address
       */
      let valid = true;

      // Quick checks
      if (!name) {
        setNameError("Please provide a name.");
        valid = false;
      } else {
        setNameError("");
      }

      if (!phoneNumber) {
        setPhoneNumberError("Please provide a phone number.");
        valid = false;
      } else {
        setPhoneNumberError("");
      }

      if (!validateEmail(email)) {
        setEmailError("Please provide a valid email address.");
        valid = false;
      } else {
        setEmailError("");
      }

      if (!selectedImage) {
        setImageError("Please add a photo landmark.");
        valid = false;
      } else {
        setImageError("");
      }

      // If no lat/long, then address text is required
      if (!currentLocation.latitude && !currentLocation.longitude) {
        if (!addressText) {
          setAddressError("Please provide an address.");
          valid = false;
        } else {
          setAddressError("");
        }
      }

      // If any field is invalid, stop here
      if (!valid) return;

      let queryParams;
      if (currentLocation.latitude && currentLocation.longitude) {
        queryParams = new URLSearchParams({
          customer_phone: customerNumber,
          salon_url_name: salonName,
          name,
          phone: phoneNumber,
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
          email, // pass email to API
        });
      } else {
        queryParams = new URLSearchParams({
          customer_phone: customerNumber,
          salon_url_name: salonName,
          name,
          address: addressText,
          phone: phoneNumber,
          email, // pass email to API
        });
      }

      // Convert the base64 image to Blob
      try {
        const response = await fetch(selectedImage);
        const imageBlob = await response.blob();

        const formData = new FormData();
        formData.append("file", imageBlob);

        const addResponse = await addCustomerAddress(queryParams, formData);
        if (addResponse.data.status === 422) {
          message.error(addResponse.data.data);
        } else if (addResponse.data.status === 201) {
          message.success(addResponse.data.data);
          fetchCustomerAddress()

          // Refetch addresses or navigate if needed
          navigate(`/${salonName}/address`);
        }
      } catch (error) {
        message.error("Error adding address. Please try again later.");
      }
    }
  };

  /**
   * Edit Address Handlers
   */
  const handleEdit = (addr) => {
    setEditingAddress(addr);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setNameError("");
    setAddressError("");
    setPhoneNumberError("");
    setImageError("");
    setEmailError("");
    setSelectedImage(null); // clear any new image selection
  };

  const handleEditSubmit = async () => {
    let isValid = true;

    if (!editingAddress.name) {
      setNameError("Please provide a name.");
      isValid = false;
    } else {
      setNameError("");
    }

    // If lat/long not chosen, address is required
    if (!currentLocation.latitude && !currentLocation.longitude) {
      if (!editingAddress.address) {
        setAddressError("Please provide an address.");
        isValid = false;
      } else {
        setAddressError("");
      }
    }

    if (!editingAddress.phone) {
      setPhoneNumberError("Please provide a phone number.");
      isValid = false;
    } else {
      setPhoneNumberError("");
    }

    // Validate email
    if (!editingAddress.email || !validateEmail(editingAddress.email)) {
      setEmailError("Please provide a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Check image
    if (!selectedImage && !editingAddress.file_path) {
      setImageError("Please add a photo landmark.");
      isValid = false;
    } else {
      setImageError("");
    }

    if (!isValid) return;

    // Build query params
    let queryParams;
    if (currentLocation.latitude && currentLocation.longitude) {
      queryParams = new URLSearchParams({
        customer_phone: customerNumber,
        address_id: editingAddress.id,
        salon_url_name: salonName,
        name: editingAddress.name,
        phone: editingAddress.phone,
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
        email: editingAddress.email,
      });
    } else {
      queryParams = new URLSearchParams({
        customer_phone: customerNumber,
        address_id: editingAddress.id,
        salon_url_name: salonName,
        name: editingAddress.name,
        address: editingAddress.address,
        phone: editingAddress.phone,
        email: editingAddress.email,
      });
    }

    try {
      const editedImage = !selectedImage
        ? await fetch(editingAddress.file_path)
        : await fetch(selectedImage);

      const imageBlob = await editedImage.blob();
      const formData = new FormData();
      formData.append("file", imageBlob);

      const editResponse = await editCustomerAddress(queryParams, formData);
      if (editResponse.data.status === 201) {
        message.success("Changes updated successfully");
      } else {
        message.error("Changes not updated");
      }

      // Refetch addresses to reflect changes
      const refetchParams = new URLSearchParams({
        phone: customerNumber,
        salon_url_name: salonName,
      });
      const refetchResponse = await getCustomerAddress(refetchParams);
      setCustomerAddresses(refetchResponse.data.data[0].address);

      setIsEditModalVisible(false);
    } catch (error) {
      message.error("Error updating address. Please try again later.");
    }
  };

  /**
   * Handle location selection from the MapPopup
   */
  const handleLocationSelect = (latitude, longitude) => {
    setCurrentLocation({ latitude, longitude });
    // Close map popup
    setShowMapPopup(false);
  };

  const handleClearLocation = () => {
    setCurrentLocation({ latitude: null, longitude: null });
  };

  return (
    <>
      <MainContainer>
        {!address ? (
          /**
           * If "address" prop is not provided,
           * show the "Select Address" screen with existing addresses
           */
          <>
            <Header>
              <div
                onClick={() => {
                  navigate(-1);
                }}
                style={{ display: "flex" }}
              >
                <LeftChevronIcon />
              </div>
              <div>{"Select Location"}</div>
            </Header>
            <AddressCard>
              <RadioGroup onChange={handleRadioChange} value={selectedAddress}>
                {customerAddresses &&
                  customerAddresses.map((addr) => (
                    <Card
                      key={addr.id}
                      style={{ width: "90vw", marginBottom: "10px" }}
                    >
                      <RadioButton value={addr.id} text={addr.name} />
                      <CardContainer>
                        <p>{`Address: ${addr.address}`}</p>
                        <p>{`Phone number: ${addr.phone}`}</p>
                        {addr.email && <p>{`Email: ${addr.email}`}</p>}
                      </CardContainer>
                      <ImageContainer>
                        {addr.file_path && (
                          <img
                            src={addr.file_path}
                            width="120px"
                            height="auto"
                            alt="Landmark"
                          />
                        )}
                      </ImageContainer>
                      <ActionButtonContainer>
                        <Button
                          text={"Edit"}
                          width={"100px"}
                          borderColor={"#F5F1FE"}
                          backgroundColor={"#F5F1FE"}
                          onClick={() => handleEdit(addr)}
                        />
                        <Button
                          text={"Delete"}
                          width={"100px"}
                          borderColor={"#FDF2F2"}
                          color={"#DE3B40"}
                          backgroundColor={"#FDF2F2"}
                          onClick={() => handleDelete(addr.id)}
                        />
                      </ActionButtonContainer>
                    </Card>
                  ))}
              </RadioGroup>
            </AddressCard>
            <AddAddressButton>
              <Button
                text={"Add new Address"}
                type={"default"}
                width={"100%"}
                borderColor={"#DEE1E6"}
                color={"#565D6D"}
                onClick={() => navigate(`/${salonName}/address/add`)}
              />
            </AddAddressButton>
          </>
        ) : (
          /**
           * If "address" prop is provided,
           * show the form to add a new address
           */
          <>
            <Header>
              <div
                onClick={() => {
                  navigate(-1);
                }}
                style={{ display: "flex" }}
              >
                <LeftChevronIcon />
              </div>
              <div>{"Add New Address"}</div>
            </Header>
            <AddressFormContainer>
              {/* Name */}
              <FormInputContainer>
                <FormTitle>Name</FormTitle>
                <FormInput
                  type="text"
                  placeholder="Enter your Name"
                  value={name}
                  onChange={(e) => {
                    const val = e.target.value.slice(0, 30);
                    setName(val);
                    setNameError("");
                  }}
                />
                {nameError && <WarningText>{nameError}</WarningText>}
              </FormInputContainer>

              {/* Address or Current Location */}
              {!currentLocation.latitude && !currentLocation.longitude && (
                <FormInputContainer>
                  <FormTitle>Address</FormTitle>
                  <FormInputTextArea
                    type="text"
                    placeholder="Enter Address"
                    value={addressText}
                    onChange={(e) => {
                      const val = e.target.value.slice(0, 100);
                      setAddressText(val);
                      setAddressError("");
                    }}
                  />
                  {addressError && <WarningText>{addressError}</WarningText>}
                </FormInputContainer>
              )}

              {/* Current location from map */}
              <AddressLocation onClick={() => setShowMapPopup(true)}>
                <LocationContainer>
                  <LocationIcon color={Theme.salonColor} /> Get my current
                  location
                </LocationContainer>
              </AddressLocation>
              {currentLocation.latitude && currentLocation.longitude && (
                <>
                  <FormTitle>
                    Latitude: {currentLocation.latitude}, Longitude:{" "}
                    {currentLocation.longitude}
                  </FormTitle>
                  <Button
                    text={"Clear selection"}
                    type={"primary"}
                    width={"30%"}
                    height={"30px"}
                    onClick={handleClearLocation}
                  />
                </>
              )}

              {/* Phone Number */}
              <FormInputContainer>
                <FormTitle>Phone number</FormTitle>
                <FormInput
                  type="text"
                  placeholder="Enter phone number with country code"
                  value={phoneNumber}
                  onChange={(e) => {
                    // Allow only digits and "+"
                    const input = e.target.value.replace(/[^0-9+]/g, "");
                    setPhoneNumber(input);
                    setPhoneNumberError("");
                  }}
                />
                {phoneNumberError && (
                  <WarningText>{phoneNumberError}</WarningText>
                )}
              </FormInputContainer>

              {/* Email */}
              <FormInputContainer>
                <FormTitle>Email</FormTitle>
                <FormInput
                  type="text"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <WarningText>{emailError}</WarningText>}
              </FormInputContainer>

              {/* Photo Landmark */}
              <FormInputContainer>
                <FormTitle>Add photo landmark</FormTitle>
                <UploadImageContainer>
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected Landmark"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "2px",
                      }}
                    />
                  ) : (
                    <>
                      <HiddenFileInput
                        id="fileInput"
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                      <ImageInput
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <PlusIcon />
                        <LogoText>Add photo</LogoText>
                      </ImageInput>
                    </>
                  )}
                </UploadImageContainer>
                {fileSizeError && (
                  <WarningText>Maximum file size is 10MB.</WarningText>
                )}
                {imageError && <WarningText>{imageError}</WarningText>}
                <ButtonContainer>
                  <RemoveImage onClick={handleRemoveImage}>
                    Remove Image
                  </RemoveImage>
                </ButtonContainer>
              </FormInputContainer>
            </AddressFormContainer>
          </>
        )}
      </MainContainer>

      {/* Submit or Save Button */}
      <SubmitContainer>
        <Button
          text={hasAddress ? "Continue" : "Save"}
          type={"primary"}
          width={"90%"}
          height={"44px"}
          onClick={handleSubmit}
          backgroundColor={Theme.salonColor}
        />
      </SubmitContainer>

      {/* Confirm Delete Modal */}
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Do you want to delete this address?</p>
      </Modal>

      {/* Edit Address Modal */}
      <Modal
        title="Edit Address"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <AntButton key="cancel" onClick={handleEditModalCancel}>
            Cancel
          </AntButton>,
          <AntButton key="submit" type="primary" onClick={handleEditSubmit}>
            Save
          </AntButton>,
        ]}
      >
        {editingAddress && (
          <AddressFormContainer>
            {/* Name */}
            <FormInputContainer>
              <FormTitle>Name</FormTitle>
              <FormInput
                type="text"
                placeholder="Enter your Name"
                value={editingAddress.name || ""}
                onChange={(e) =>
                  setEditingAddress({
                    ...editingAddress,
                    name: e.target.value.slice(0, 30),
                  })
                }
              />
              {nameError && <WarningText>{nameError}</WarningText>}
            </FormInputContainer>

            {/* Address (if no lat/long) */}
            {!currentLocation.latitude && !currentLocation.longitude && (
              <FormInputContainer>
                <FormTitle>Address</FormTitle>
                <FormInputTextArea
                  type="text"
                  placeholder="Enter Address"
                  value={editingAddress.address || ""}
                  onChange={(e) =>
                    setEditingAddress({
                      ...editingAddress,
                      address: e.target.value.slice(0, 100),
                    })
                  }
                />
                {addressError && <WarningText>{addressError}</WarningText>}
              </FormInputContainer>
            )}

            {/* Get Current Location */}
            <AddressLocation onClick={() => setShowMapPopup(true)}>
              <LocationContainer>
                <LocationIcon color={Theme.salonColor} /> Get my current location
              </LocationContainer>
            </AddressLocation>

            {/* If lat/long is selected */}
            {currentLocation.latitude && currentLocation.longitude && (
              <>
                <FormTitle>
                  Latitude: {currentLocation.latitude}, Longitude:{" "}
                  {currentLocation.longitude}
                </FormTitle>
                <Button
                  text={"Clear selection"}
                  type={"primary"}
                  width={"30%"}
                  height={"30px"}
                  onClick={handleClearLocation}
                />
              </>
            )}

            {/* Phone number */}
            <FormInputContainer>
              <FormTitle>Phone number</FormTitle>
              <FormInput
                type="text"
                placeholder="Enter phone number with country code"
                value={editingAddress.phone || ""}
                onChange={(e) => {
                  const input = e.target.value.replace(/[^0-9+]/g, "");
                  setEditingAddress({
                    ...editingAddress,
                    phone: input.slice(0, 15),
                  });
                }}
              />
              {phoneNumberError && (
                <WarningText>{phoneNumberError}</WarningText>
              )}
            </FormInputContainer>

            {/* Email */}
            <FormInputContainer>
              <FormTitle>Email</FormTitle>
              <FormInput
                type="text"
                placeholder="Enter your email address"
                value={editingAddress.email || ""}
                onChange={(e) =>
                  setEditingAddress({ ...editingAddress, email: e.target.value })
                }
              />
              {emailError && <WarningText>{emailError}</WarningText>}
            </FormInputContainer>

            {/* Photo Landmark */}
            <FormInputContainer>
              <FormTitle>Add photo landmark</FormTitle>
              <UploadImageContainer>
                {selectedImage || editingAddress.file_path ? (
                  <img
                    src={selectedImage || editingAddress.file_path}
                    alt="Landmark"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "2px",
                    }}
                  />
                ) : (
                  <>
                    <HiddenFileInput
                      id="editFileInput"
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                    <ImageInput
                      onClick={() =>
                        document.getElementById("editFileInput").click()
                      }
                    >
                      <PlusIcon />
                      <LogoText>Add photo</LogoText>
                    </ImageInput>
                  </>
                )}
              </UploadImageContainer>
              {fileSizeError && (
                <WarningText>Maximum file size is 10MB.</WarningText>
              )}
              {imageError && <WarningText>{imageError}</WarningText>}
              <ButtonContainer>
                <RemoveImage onClick={handleRemoveImage}>
                  Remove Image
                </RemoveImage>
              </ButtonContainer>
            </FormInputContainer>
          </AddressFormContainer>
        )}
      </Modal>

      {/* Map Popup */}
      {showMapPopup && (
        <MapPopup
          visible={true}
          onClose={() => setShowMapPopup(false)}
          onLocationSelect={handleLocationSelect}
        />
      )}
    </>
  );
};

export default AddressPage;
